import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const AboutPage = (props) => (
  <Layout location={props.location}>
    <SEO title="About Us"/>
    <h1 style={styles.header}>About Coastal Advisory Group</h1>
    <div style={styles.body}>
      <h3 style={{...styles.italic, ...styles.primaryText}}>Wealth Management for Today - and Tomorrow</h3>
      <p>
        The Coastal Advisory Group is the culmination of many years of business development for Michael Mueller. In 1998 
        Michael became a licensed Financial Advisor and joined forces with Richard McCracken, an original co-founder of 
        the broker-dealer, Coastal Equities. In 2004, Michael purchased Coastal Equities and retained Richard McCracken as 
        Midwest Principal. Coastal Equities grew into a powerhouse of forty-eight advisors in eight states, with licensure 
        in more than 31 states.
      </p>
      <p>
        In 2005, Michael formed The Coastal Advisory Group, LLC and licensed it in 2006 as a fee-based investment firm 
        dedicated to providing the maximum benefits and service to his clients. In mid-2011, the Coastal Advisory 
        Group merged with Donnelly Steen & Company, an SEC Registered Investment Advisor to provide an even greater depth 
        of resources for our clients. In 2012, Coastal Investment Advisors was formed to bring 14 advisory groups together. 
        By combining our assets we are able to provide more choices for our clients with special circumstances.
      </p>
      <p>
        Much of Michael’s success as a wealth manager is due to his extensive business experience which is complimented 
        by his warm personal nature and desire to treat his select clients as part of his family and business partners.
      </p>
      <p>
        As the President of the Coastal Advisory Group, Michael is joined by independent Investment Adviser 
        Representatives who offer their own unique insight and experience to the group. The Coastal Advisory Group 
        operates with approximately $95 million under management and has established itself as one of the region’s 
        premiere wealth management firms for individuals and multi-generational families.
      </p>
      <p>
        The Coastal Advisory Group chooses its partners carefully, and has relationships with three of the nation’s 
        Leading Custodians — First Clearing, LLC, an affiliate of Wells Fargo. E*TRADE Advisor Services, Trustco/Bank 
        of New York Mellon and MainStar Trust
      </p>
    </div>
  </Layout>
)

let styles={
  header: {
    color: '#8d1b38',
    padding: '35px 15px 0 25px'
  },
  primaryText: {
    color: '#8d1b38'
  },
  bullet: {
    paddingLeft: '30px'
  },
  secondaryText: {
    color: '#083f95',
    fontWeight: '700'
  },
  italic: {
    fontStyle: 'italic'
  },
  body: {
    padding: '0 60px 30px 60px',
    color: 'black'
  }
}

export default AboutPage
